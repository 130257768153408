/**
 * Represents a message listener that handles different types of messages. It is used to stablish communication with the ExternalIframe component.
 */
export class MessageListener {
  eventHandlers: EventHandler[] = [];

  /**
   * Adds a message handler for a specific message type.
   * @param type - The type of the message.
   * @param action - The action to be performed when the message is received.
   */
  addMessage(type: string, action: (message: string) => void) {
    this.eventHandlers.push(new EventHandler(type, action));
  }

  /**
   * Handles the incoming message event and triggers the appropriate action.
   * @param event - The message event to be handled.
   */
  handle(event: MessageEvent) {
    const eventHandler = this.eventHandlers.find((eventHandler) => eventHandler.canHandle(event));
    if (eventHandler) eventHandler.triggerFor(event.data.message);
  }
}

class EventHandler {
  constructor(public type: string, public action: (message: string) => void) { }

  /**
   * Checks if the event can be handled by this event handler.
   * @param event - The message event to be checked.
   * @returns True if the event can be handled, false otherwise.
   */
  canHandle(event: MessageEvent): boolean {
    return event.data.type === this.type;
  }

  /**
   * Triggers the action associated with this event handler.
   * @param message - The message to be passed to the action.
   */
  triggerFor(message: string): void {
    this.action(message);
  }
}
