import Home from "./pages/Home";
import CalSync from "./pages/calsync";
import SyncCalendars from "./pages/calsync/syncCalendars";
import EasyClock from "./pages/easyclock";
import EasyClockSettings from "./pages/easyclock/settings";
import Login from "./pages/Login";
import Error from "./pages/Error";
import { Navigate } from "react-router-dom";

export const paths = {
  calendarSync: "/calendar-syncs",
  events: "/events",
  settings: "/settings",
  newCalendarSync: "/calendar-sync/new",
  login: "/login",
  notFound: "*",
  error: "/error",
  root: "/",
}

const routes = [
  {
    path: paths.root,
    element: <Home />,
    children: [
      {
        path: "",
        sidebar: false,
        element: <Navigate to={paths.events} />
      },
      {
        path: paths.events,
        name: "Events",
        icon: "ni ni-watch-time",
        element: <EasyClock />,
        sidebar: true
      },
      {
        path: paths.calendarSync,
        name: "Calendar Sync",
        icon: "ni ni-calendar-grid-58",
        element: <CalSync />,
        sidebar: true
      },
      {
        path: paths.settings,
        name: "Settings",
        element: <EasyClockSettings />,
        sidebar: false
      },
      {
        path: paths.newCalendarSync,
        name: "New Calendar Sync",
        element: <SyncCalendars />,
        sidebar: false
      },
    ]
  },
  {
    path: paths.login,
    name: "Login",
    element: <Login />,
  },
  {
    path: paths.error,
    name: "Server Error",
    element: <Error errorCode={500} />,
  },
  {
    path: paths.notFound,
    name: "Not Found",
    element: <Error errorCode={404} />,
  },
];

const rootRoute = routes.find(r => r.path === "/");
export const sidebarRoutes = rootRoute?.children?.filter(c => c.sidebar === true) || [];

export default routes;
