import React, { useState } from "react";
import { Button, Card, Table } from "reactstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlinePlusCircle, AiOutlineDelete } from "react-icons/ai";
import { getCurrentWeek, getDateNow, getEndOfWeek, getStartOfWeek, parseDate, isFutureEvent } from "../../utils/timeUtils";
import { IEasyClockProject, IEasyClockEvent, IEasyClockTimeEntriesConfigs, IClockifyTag, IEasyClockTimeEntryConfig } from "../../services/easyclock/interfaces";
import "./events-table.css";
import { EVENT_STATUS, EVENT_ACTION } from "../../constants/constants";
import { COMMON_MESSAGES } from "./constants";
import FakeRows from "../FakeRows";
import { shouldRenderInfo } from "../../utils/renderEvents";
import DropdownProjects from "../DropdownProjects";
import DropdownTags from "../DropdownTags";
import BillableInput from "../BillableInput";
import Duration from "../Duration";

interface EventsTableProps {
  googleEvents: IEasyClockEvent[] | null;
  easyClockTimeEntriesConfigs: IEasyClockTimeEntriesConfigs;
  clockifyProjects: IEasyClockProject[] | null;
  clockifyTags: IClockifyTag[] | null;
  showingSelectedWeek: number;
  isLoading: boolean;
  defaultClockifyTag: string;

  setSelectedWeek: (week: number) => void;
  setEasyClockTimeEntriesConfigs: (easyClockTimeEntriesConfigs: IEasyClockTimeEntriesConfigs) => void;
  handleChangeEventStatus(event: IEasyClockEvent, action: string): void;
  handleCreateTimeEntry: (event: IEasyClockEvent) => void;
}

const EventsTable: React.FC<EventsTableProps> = (props) => {

  const {
    googleEvents,
    easyClockTimeEntriesConfigs,
    clockifyProjects,
    clockifyTags,
    showingSelectedWeek,
    isLoading,
    defaultClockifyTag,

    setSelectedWeek,
    setEasyClockTimeEntriesConfigs,
    handleChangeEventStatus,
    handleCreateTimeEntry,
  } = props;

  const [showIgnored, setShowIgnored] = useState(false);
  const [showAdded, setShowAdded] = useState(false);
  const [showingTitleWeek, setTitleWeek] = useState<string>(COMMON_MESSAGES.DEFAULT_WEEK_TITLE);

  const setChangeWeek = (week: number) => {
    setSelectedWeek(week);
    if (week == getCurrentWeek()) {
      setTitleWeek(COMMON_MESSAGES.DEFAULT_WEEK_TITLE);
    }else if (week == getCurrentWeek()-1) {
      setTitleWeek(COMMON_MESSAGES.LAST_WEEK_TITLE);
    }else{
      setTitleWeek(`${getStartOfWeek(week)} - ${getEndOfWeek(week)}`);
    }
  }

  const handlePrevWeek = () => setChangeWeek(showingSelectedWeek - 1);

  const handleNextWeek = () => setChangeWeek(showingSelectedWeek + 1);

  const handleSetThisWeek = () => setChangeWeek(getCurrentWeek());

  const getActionButtons = (event: IEasyClockEvent) => {
    if (event.status === EVENT_STATUS.IGNORED) {
      return (
        <>
          <Button color="primary" onClick={() => handleChangeEventStatus(event, EVENT_ACTION.RETRIEVE)}>
            <AiOutlinePlusCircle/>
          </Button>
        </>
      )
    }
    if (event.status === EVENT_STATUS.ADDED) {
      return (
        <>
          <Button color="danger" onClick={() => handleChangeEventStatus(event, EVENT_ACTION.DELETE)}>
            <AiOutlineDelete/>
          </Button>
        </>
      )
    }
    if (parseDate(event.start_date) < getDateNow()){
      return (
        <>
          <Button color="success" onClick={() => handleCreateTimeEntry(event)}>
            <AiOutlineCheckCircle/>
          </Button>
          <Button color="danger" onClick={() => handleChangeEventStatus(event, EVENT_ACTION.IGNORE)}>
            <AiOutlineCloseCircle/>
          </Button>
        </>
      )
    }
    return null;
  }

  const setEasyClockTimeEntryConfig = (event: IEasyClockEvent) => (easyClockTimeEntryConfig: IEasyClockTimeEntryConfig) => setEasyClockTimeEntriesConfigs({...easyClockTimeEntriesConfigs, [event.id]: easyClockTimeEntryConfig})

  return(
    <>
      <Card className="shadow">
        <div className="custom-control custom-switch d-flex justify-content-between align-items-center pt-1 pb-1 pr-4 pl-4">
          <div className="d-flex align-content-center">
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="ignoredSwitch"
                checked={showIgnored}
                onChange={() => setShowIgnored(!showIgnored)}/>
              <label className="custom-control-label" htmlFor="ignoredSwitch">Ignored</label>
            </div>
            <div className="custom-control custom-switch ml-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="addedSwitch"
                checked={showAdded}
                onChange={() => setShowAdded(!showAdded)}/>
              <label className="custom-control-label" htmlFor="addedSwitch">Added</label>
            </div>
          </div>
          <div className="week-filter">
            <Button className="btn-sm p-1 mr-3" color="primary" onClick={() => handlePrevWeek()}>
              <IoIosArrowBack/>
            </Button>
            <span>{showingTitleWeek}</span>
            <Button className="btn-sm p-1 ml-3" color="primary" onClick={() => handleNextWeek()} disabled={showingTitleWeek===COMMON_MESSAGES.DEFAULT_WEEK_TITLE}>
              <IoIosArrowForward/>
            </Button>
          </div>
          <div>
            <Button className="btn-sm p-1 btn-this-week" color="primary" onClick={() => handleSetThisWeek()}>
              { COMMON_MESSAGES.DEFAULT_WEEK_TITLE }
            </Button>
          </div>
        </div>
        <Table className="align-items-center easy-clock-table" responsive>
          <thead className="thead-light">
            <tr>
              <th className="easy-clock-event-col" scope="col">Event</th>
              <th className="easy-clock-start-time-col" scope="col">Start time</th>
              <th className="easy-clock-duration-col" scope="col">Duration</th>
              <th className="easy-clock-project-col" scope="col">Project</th>
              <th className="easy-clock-billable-head" scope="col">$</th>
              <th className="easy-clock-tags-col" scope="col">Tags</th>
              <th className="easy-clock-action-col" scope="col">Action</th>
            </tr>
          </thead>
          { isLoading ? (
            <FakeRows columns={7} rows={3}/>
          ) : (
          <tbody>
            {googleEvents?.map((event: IEasyClockEvent) => {
              if (!shouldRenderInfo(event, showIgnored, showAdded)) return
              const futureEvent = isFutureEvent(event);
                return (
                  <tr key={event.id}>
                    <td className="easy-clock-event-col easy-clock-td-event">
                      <div className="d-flex flex-column">
                        <span className={(futureEvent) ? "text-muted" : ""}>
                          {event.title}
                        </span>
                      </div>
                    </td>
                    <td className={`easy-clock-start-time-col td-start-time ${futureEvent ? "text-muted" : ""}`}>
                      {parseDate(event.start_date).format("DD / MM HH:mm")}
                    </td>
                    <td className="easy-clock-duration-col">
                      <Duration
                          easyClockTimeEntryConfig={easyClockTimeEntriesConfigs[event.id]} 
                          setEasyClockTimeEntryConfig={setEasyClockTimeEntryConfig(event)} 
                          disabled={futureEvent}                    
                      />
                    </td>
                    <td className="easy-clock-project-col">
                      <DropdownProjects
                          clockifyProjects={clockifyProjects}
                          easyClockTimeEntryConfig={easyClockTimeEntriesConfigs[event.id]} 
                          disabled={futureEvent} 
                          setEasyClockTimeEntryConfig={setEasyClockTimeEntryConfig(event)} 
                          />
                    </td>
                    <td className="easy-clock-billable-col">
                      <BillableInput
                        easyClockTimeEntryConfig={easyClockTimeEntriesConfigs[event.id]} 
                        disabled={futureEvent} 
                        setEasyClockTimeEntryConfig={setEasyClockTimeEntryConfig(event)} 
                      />
                    </td>
                    <td className="easy-clock-tags-col">
                      <DropdownTags
                        clockifyTags={clockifyTags}
                        defaultClockifyTag={defaultClockifyTag}
                        easyClockTimeEntryConfig={easyClockTimeEntriesConfigs[event.id]} 
                        disabled={futureEvent} 
                        setEasyClockTimeEntryConfig={setEasyClockTimeEntryConfig(event)} 
                      />
                    </td>
                    <td className="easy-clock-action-col">
                    {getActionButtons(event)}
                    </td>
                  </tr>
                )
            })}
          </tbody>
          )}
        </Table>
      </Card>
    </>
  )
};

export default EventsTable;