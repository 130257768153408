import {calSyncClient} from "../common/http";
import { ICalendarSync } from "./interfaces";

export async function createSync(source: string, target: string) {
  const body = { source: source, target: target };

  return calSyncClient().httpPost<ICalendarSync>("api/v1/calendar_syncs", body);
}

export async function getSyncs() {
  return calSyncClient().httpGet<ICalendarSync[]>("api/v1/calendar_syncs");
}

export async function deleteSync(syncId: string) {
  await calSyncClient().httpDelete(`api/v1/calendar_syncs/${syncId}`);
}

export async function enableSync(syncId: string) {
  return calSyncClient().httpPost<ICalendarSync>(`api/v1/calendar_syncs/${syncId}/enable`);
}

export async function disableSync(syncId: string) {
  return calSyncClient().httpPost<ICalendarSync>(`api/v1/calendar_syncs/${syncId}/disable`);
}
