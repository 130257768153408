import React from "react";
import {Dropdown } from "react-bootstrap";
import { IClockifyTag, IEasyClockTimeEntryConfig } from "../../services/easyclock/interfaces";
import "./dropdown-tags.css";

interface DropdownTagsProps {
  clockifyTags: IClockifyTag[] | null;
  easyClockTimeEntryConfig: IEasyClockTimeEntryConfig;
  disabled?: boolean;
  defaultClockifyTag: string;
  showTags?: boolean;

  setEasyClockTimeEntryConfig: (easyClockTimeEntryConfig: IEasyClockTimeEntryConfig) => void;
}

const DropdownTags: React.FC<DropdownTagsProps> = (props) => {

  const {
    clockifyTags,
    defaultClockifyTag,
    disabled,
    easyClockTimeEntryConfig,
    showTags,

    setEasyClockTimeEntryConfig,
  } = props;

  const isTagSelected = (tagId: string) => {
    return easyClockTimeEntryConfig.clockifyTagIds.includes(tagId);
  };

  const handleTagSelection = (selectedTagId: string, checked: boolean) => {
    const updatedSettings: IEasyClockTimeEntryConfig = { ...easyClockTimeEntryConfig };
    let currentTagIds = [...updatedSettings.clockifyTagIds];

    if (checked && !currentTagIds.includes(selectedTagId)) {
      currentTagIds.push(selectedTagId);
    } else if (!checked && currentTagIds.includes(selectedTagId)) {
      currentTagIds = currentTagIds.filter((tagId: string) => tagId !== selectedTagId);
    }
    updatedSettings.clockifyTagIds = currentTagIds;

    setEasyClockTimeEntryConfig(updatedSettings)
  };

  const handleUnselectAll = () => {
    const updatedSettings: IEasyClockTimeEntryConfig = { ...easyClockTimeEntryConfig };
    updatedSettings.clockifyTagIds = [defaultClockifyTag];

    setEasyClockTimeEntryConfig(updatedSettings)
  };

  function selectedTagNames(): string[] {
    return easyClockTimeEntryConfig.clockifyTagIds
      .map((tagId) => clockifyTags?.find((tag) => tag.id === tagId))
      .filter((tag): tag is IClockifyTag => !!tag)
      .map((tag) => tag.name);
  }
  return (
    <>
      <Dropdown className="easy-clock-tags-dropdown">
        {showTags ? toggleShowingTags() : toggleNotShowingTags()}
        <Dropdown.Menu className="easy-clock-tags-dropdown-menu">
          {clockifyTags?.map((tag) => (
            <Dropdown.Item
              key={tag.id}
              onClick={() => handleTagSelection(tag.id, !isTagSelected(tag.id))}
              active={isTagSelected(tag.id)}
            >
              {tag.name}
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => handleUnselectAll()}>
            Unselect All
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );

  function toggleNotShowingTags(): React.ReactNode {
    return (
      <Dropdown.Toggle variant="outline-dark" disabled={disabled || false} />
    );
  }

  function toggleShowingTags() {
    return (
      <Dropdown.Toggle
        variant="outline-dark"
        disabled={disabled || false}
        className="displaying-tags"
      >
        {selectedTagNames().join(", ") || "Select tags"}
      </Dropdown.Toggle>
    );
  }
};

export default DropdownTags;
