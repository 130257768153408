import React from "react";
import "./fake-rows.css";
import Shimmer from "../Shimmer";

interface FakeRowsProps {
  columns: number;
  rows: number;
}

const FakeRows: React.FC<FakeRowsProps> = ({columns, rows}) => {

  const columnsArray = Array.from({ length: columns }, (_, index) => index);
  const rowsArray = Array.from({ length: rows }, (_, index) => index);

  return (
    <>
      <tbody>
        {rowsArray.map((index) => (
          <tr className={`load load${index + 1}`} style={{ animationDelay: `${0.2 * index}s` }} key={index}>
            {columnsArray.map((_columnIndex) => (
              <td key={_columnIndex}><Shimmer width="94%" height="12px"/></td>
            ))}
          </tr>
        ))}
      </tbody>
    </>
  )
};

export default FakeRows;