import { Navigate, Outlet } from "react-router-dom";
import { loggedIn, readUser } from "../../services/auth";
import Navbar from "../../components/Navbars";
import {paths, sidebarRoutes} from "../../routes";
import Sidebar from "../../components/Sidebar";
import CrunchLoopLogo from "../../assets/images/crunchlogo.png";
import Notification from "../../components/Notifications";
import "./layout.css";
import {useNotificationContext, useNotificationActionContext} from "../../contexts/notificationContext";

function Layout() {
  const currentUser = readUser();
  const notifications = useNotificationContext()
  const notificationActions = useNotificationActionContext()

  if (!loggedIn()) {
    return <Navigate to={paths.login} />;
  }

  return (
    <>
      <Sidebar
        routes={sidebarRoutes}
        logo={{
          innerLink: paths.events,
          imgSrc: CrunchLoopLogo,
          imgAlt: "...",
        }}{...currentUser!}
      />
      <div className="main-content h-100">
        {/* TODO: Review if NavBar should be scoped the .main-content */}
        <Navbar {...currentUser!} />
        <Outlet />
        <div className="notification-container">
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              type={notification.type}
              message={notification.message}
              onClose={() => notificationActions?.removeNotification(notification.id)}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Layout;
