import * as Sentry from "@sentry/react";

export const captureException = (exception: Sentry.Exception | unknown) => {
  Sentry.captureException(exception);
};

export const captureEvent = (event: Sentry.Event) => {
  Sentry.captureEvent(event);
};

export const initialize = () => {
  Sentry.init({
    dsn: "https://bee1034b53b71fa89e02691c168d0e01@o4506972474245120.ingest.us.sentry.io/4506972964782080",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    // eslint-disable-next-line no-undef
    environment: process.env.NODE_ENV,
  });
  captureEvent({
    message: "App loaded",
    level: "info",
  });
};
