import { useState, FC, useEffect } from "react";
import {
    IEasyClockProject,
    IClockifyTag,
    IEasyClockTimeEntryConfig,
} from "../../services/easyclock/interfaces";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DropdownTags from "../../components/DropdownTags";
import DropdownProjects from "../DropdownProjects";
import { calculateEndDate, formatDate, parseDate } from "../../utils/timeUtils";
import BillableInput from "../BillableInput";
import { updateTime } from "../../utils/timeUtils";
import Duration from "../Duration";
import { Description } from "../Description";
import { DEFAULT_START_HOUR } from "../../constants/constants";

interface AddHourModalProps {
    clockifyTags: IClockifyTag[] | null;
    clockifyProjects: IEasyClockProject[] | null;
    workspaceId: string | null;
    dateToBeUploaded: string;
    show: boolean;
    setShow: (show: boolean) => void;
    startingDuration: string;
    handleCreateTimeEntry: (timeEntry: IEasyClockTimeEntryConfig) => void;
    isLoading: boolean;
}
const AddHourModal: FC<AddHourModalProps> = (props) => {
    const {
        clockifyTags,
        clockifyProjects,
        workspaceId,
        dateToBeUploaded,
        show,
        setShow,
        startingDuration,
        handleCreateTimeEntry,
        isLoading,
    } = props;

    const getInitialConfig = () => {
        const startDate = updateTime(dateToBeUploaded, DEFAULT_START_HOUR);
        const endDate = formatDate(calculateEndDate(
            startDate,
            startingDuration
        ));
        return {
            workspace_id: workspaceId,
            description: "",
            start_date: startDate,
            end_date: endDate,
            clockifyProject: null,
            clockifyTagIds: [""],
            billable: false,
            duration: startingDuration,
        };
    };

    const [easyClockTimeEntryConfig, setEasyClockTimeEntryConfig] =
        useState<IEasyClockTimeEntryConfig>(getInitialConfig());

    const toggle = () => setShow(!show);

    useEffect(() => {
        if (show) {
            setEasyClockTimeEntryConfig(getInitialConfig());
        }
    }, [show]);

    return (
        <div>
            <Modal isOpen={show} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    Uploading hour on
                    {` ${parseDate(dateToBeUploaded).format("DD-MM-YYYY")}`}
                </ModalHeader>
                <ModalBody>
                    <table>
                        <tr>
                            <td>
                                <span> Description: </span>
                            </td>
                            <td>
                                <Description
                                    easyClockTimeEntryConfig={
                                        easyClockTimeEntryConfig
                                    }
                                    setEasyClockTimeEntryConfig={
                                        setEasyClockTimeEntryConfig
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span> Select Project: </span>
                            </td>
                            <td>
                                <DropdownProjects
                                    clockifyProjects={clockifyProjects}
                                    easyClockTimeEntryConfig={
                                        easyClockTimeEntryConfig
                                    }
                                    disabled={false}
                                    setEasyClockTimeEntryConfig={
                                        setEasyClockTimeEntryConfig
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span> Select Tags: </span>
                            </td>
                            <td>
                                <DropdownTags
                                    clockifyTags={clockifyTags}
                                    defaultClockifyTag={""}
                                    easyClockTimeEntryConfig={
                                        easyClockTimeEntryConfig
                                    }
                                    setEasyClockTimeEntryConfig={
                                        setEasyClockTimeEntryConfig
                                    }
                                    showTags
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span> Select Duration: </span>
                            </td>
                            <td>
                                <Duration
                                    easyClockTimeEntryConfig={
                                        easyClockTimeEntryConfig
                                    }
                                    setEasyClockTimeEntryConfig={
                                        setEasyClockTimeEntryConfig
                                    }
                                    disabled={false}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span> Billable? </span>
                            </td>
                            <td>
                                <BillableInput
                                    easyClockTimeEntryConfig={
                                        easyClockTimeEntryConfig
                                    }
                                    disabled={false}
                                    setEasyClockTimeEntryConfig={
                                        setEasyClockTimeEntryConfig
                                    }
                                />
                            </td>
                        </tr>
                    </table>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="success"
                        onClick={() => {
                            handleCreateTimeEntry(easyClockTimeEntryConfig)
                            toggle()
                            }
                        }
                        disabled={isLoading}
                    >
                        Add hour
                    </Button>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AddHourModal;
