import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { IEasyClockProject, IEasyClockTimeEntryConfig } from "../../services/easyclock/interfaces";
import "./dropdown-projects.css";


interface DropdownProjectsProps {
  easyClockTimeEntryConfig: IEasyClockTimeEntryConfig;
  clockifyProjects: IEasyClockProject[] | null;
  disabled?: boolean;
  setEasyClockTimeEntryConfig: (easyClockTimeEntryConfig: IEasyClockTimeEntryConfig) => void;
}

const DropdownProjects: React.FC<DropdownProjectsProps> = (props) => {

  const {
    clockifyProjects,
    setEasyClockTimeEntryConfig,
    easyClockTimeEntryConfig,
    disabled
  } = props;

  const handleProjectChange = (project: IEasyClockProject) => {
    const selectedProject = project;
    const updatedSettings: IEasyClockTimeEntryConfig = { ...easyClockTimeEntryConfig };
    updatedSettings.clockifyProject = selectedProject;
    setEasyClockTimeEntryConfig(updatedSettings)
  }

  return (
    <>
      <DropdownButton
        className="easy-clock-project-dropdown"
        variant="outline-dark"
        disabled={disabled || false}
        title={easyClockTimeEntryConfig.clockifyProject?.name || "Select Project"}
      >
        {clockifyProjects?.map((project) => (
          <Dropdown.Item
          key={project.id}
          onClick={() => handleProjectChange(project)}
          >
            {project.name}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  );
};

export default DropdownProjects;