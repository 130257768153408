import React from "react";
import ServerError from "../../components/Errors/serverError";
import NotFound from "../../components/Errors/notFound";

interface ErrorPageProps {
  errorCode: number;
}

function ErrorPage({ errorCode }: ErrorPageProps) {
  return errorCode === 404 ? <NotFound /> : <ServerError />;
}

export default ErrorPage;