import { useEffect, useState, useCallback } from "react";
import { IEasyClockProject, IEasyClockEvent, IEasyClockTimeEntriesConfigs, IEasyClockTimeEntryConfig, IClockifyTag, IEasyClock, IEasyClockTimeEntry } from "../../services/easyclock/interfaces";
import { getEvents, getTags, getProjects, getTimeEntries, ignoreEvent, deleteTimeEntry, createTimeEntry, resetEventStatus, createTimeEntryWithoutEvent, getUserSettings } from "../../services/easyclock/easyClock";
import { createGoogleAccount } from "../../services/common/googleAccount";
import Header from "../../components/Headers";
import EventsTable from "../../components/EventsTable";
import { Container, Button } from "reactstrap";
import { useNotificationActionContext } from "../../contexts/notificationContext";
import { formatDate, formatDuration, getCurrentWeek, getEndOfWeek, getStartOfWeek, parseDate } from "../../utils/timeUtils";
import { EVENT_STATUS, EVENT_ACTION } from "../../constants/constants";
import { captureException } from "../../sentry";
import Week from "../../components/Week";
import { getUserId } from "../../services/auth";
import { isWorkingDay } from "../../utils/timeUtils";
import { getDuration } from "../../utils/timeUtils";
import { STANDARD_WEEKLY_WORKING_HOURS } from "../../constants/constants";

function EasyClock() {
  const notificationActions = useNotificationActionContext();
  const [clockifyProjects, setClockifyProjects] = useState<IEasyClockProject[] | null>(null);
  const [clockifyTags, setClockifyTags] = useState<IClockifyTag[] | null>(null);
  const [googleEvents, setGoogleEvents] = useState<IEasyClockEvent[] | null>(null);
  const [workspaceID, setWorkspaceID] = useState<string | null>(null);
  const [easyClockTimeEntriesConfigs, setEasyClockTimeEntriesConfigs] = useState<IEasyClockTimeEntriesConfigs>({});
  const [showingSelectedWeek, setSelectedWeek] = useState<number>(getCurrentWeek());
  const [isLoading, setIsLoading] = useState(true);
  const [timeEntries, setTimeEntries] = useState<IEasyClockTimeEntry[]>([]);
  const [weeklyWorkingHours, setweeklyWorkingHours] = useState<number>(STANDARD_WEEKLY_WORKING_HOURS);

  const defaultClockifyTag = process.env.REACT_APP_CLOCKIFY_DEFAULT_TAG || "";

  const initializeTimeEntriesSettings = useCallback( async(response: IEasyClock) => {
    const settings: IEasyClockTimeEntriesConfigs = {};
    for (const event of response.events) {
      settings[event.id] = {
        workspace_id: workspaceID,
        googleEventId: event.google_id,
        description: event.title,
        start_date: formatDate(event.start_date),
        end_date: formatDate(event.end_date),
        clockifyProject: null,
        clockifyTagIds: [defaultClockifyTag],
        billable: false,
        duration: formatDuration(getDuration(event.start_date, event.end_date)),
      };
    }
    setEasyClockTimeEntriesConfigs(settings);
  }, [workspaceID, setEasyClockTimeEntriesConfigs, defaultClockifyTag])

  const fetchEvents = useCallback(async () => {
    try {
      const response = await getEvents(getStartOfWeek(showingSelectedWeek), getEndOfWeek(showingSelectedWeek));
      setGoogleEvents(response.events);
      initializeTimeEntriesSettings(response);
    } catch (error) {
      notificationActions?.pushErrorNotificationMessage("Error retrieving events. Try Again");
    }
  }, [setGoogleEvents, initializeTimeEntriesSettings, notificationActions, showingSelectedWeek]);

  const fetchTags = useCallback(async () => {
    try {
      const response = await getTags();
      setClockifyTags(response.tags);
    } catch (error) {
      notificationActions?.pushErrorNotification("Error retrieving tags. Try Again");
    }
  }, [setClockifyTags, notificationActions]);

  const fetchProjects = useCallback(async () => {
    try {
      const response = await getProjects();
      setClockifyProjects(response.projects);
    } catch (error) {
      notificationActions?.pushErrorNotification("Error retrieving projects. Try Again");
    }
  }, [setClockifyProjects, notificationActions]);

  const fetchTimeEntries = useCallback(async () => {
    try {
      const response = await getTimeEntries();
      setTimeEntries(response);
    } catch (error) {
      captureException(error);
      notificationActions?.pushErrorNotification(
        "Error retrieving projects. Try Again"
      );
    }
  }, [notificationActions, setTimeEntries]);

  const setClockifyWorkspaceId = () =>
    setWorkspaceID(process.env.REACT_APP_CRUNCHLOOP_CLOCKIFY_WORKSPACE_ID!);

  const fetchUserSettings = useCallback(async () => {
    const userId = getUserId();
    if (userId) {
      try {
        const response = await getUserSettings(userId);
        setweeklyWorkingHours(response.weekly_working_hours);
      } catch (error) {
        captureException(error);
        notificationActions?.pushErrorNotification(
          "Error retrieving projects. Try Again"
        );
      }
    }
  }, [notificationActions, setTimeEntries]);

  useEffect(() => {
    Promise.all(
      [
        fetchEvents(),
        fetchTags(),
        fetchProjects(),
        fetchTimeEntries(),
        fetchUserSettings()
      ]).then(()=>{
      setClockifyWorkspaceId();
      setIsLoading(false);
    })
  }, [fetchEvents, fetchTags, fetchProjects, fetchTimeEntries, fetchUserSettings]);

  const AddgoogleAccount = () =>
    window?.google?.accounts.oauth2
      .initCodeClient({
        client_id: process.env.REACT_APP_EASYCLOCK_CLIENT_ID,
        scope: process.env.REACT_APP_EASYCLOCK_GOOGLE_SCOPES,
        callback: async (response: { code: string }) => {
          try {
            await createGoogleAccount(response.code, process.env.REACT_APP_EASYCLOCK_BASE_URL);
            notificationActions?.pushSuccessNotification("Account Added succesfully. Add your Clockify API key in Settings.");
            fetchEvents();
          } catch (error) {
            notificationActions?.pushErrorNotification(error);
          }
        },
        error_callback: (nonOAuthError: { message: string }) => {
          notificationActions?.pushErrorNotificationMessage(nonOAuthError.message);
        },
      })
      .requestCode();

  const handleAddAccount = async () => {
    AddgoogleAccount();
    await fetchEvents();
  };

  const refreshEvents = async (googleEventId: string) => {
    if (googleEvents) {
      const updatedEvents = googleEvents.filter(event => event.google_id !== googleEventId);
      setGoogleEvents(updatedEvents);
    }
  };

  const setDefaultClockifyTag = (eventToLoad: IEasyClockTimeEntryConfig) => {
    if (eventToLoad.clockifyTagIds.length > 0) {
      return eventToLoad;
    } else {
      eventToLoad.clockifyTagIds.push(defaultClockifyTag);
      return eventToLoad;
    }
  }

  const handleCreateTimeEntry = async (event: IEasyClockEvent) => {
    if (googleEvents && easyClockTimeEntriesConfigs) {
      setIsLoading(true);
      const eventToLoad = easyClockTimeEntriesConfigs[event.id];
      setDefaultClockifyTag(eventToLoad);
      if (eventToLoad && eventToLoad?.clockifyProject?.id) {
        try {
          if (workspaceID !== null && eventToLoad.googleEventId) {
            await createTimeEntry(
              eventToLoad.googleEventId,
              workspaceID,
              eventToLoad.description,
              eventToLoad.clockifyProject.id,
              eventToLoad.billable,
              eventToLoad.clockifyTagIds,
              formatDate(eventToLoad.start_date),
              formatDate(eventToLoad.end_date)
            );
            refreshEvents(eventToLoad.googleEventId);
          }
          const response = await getEvents(getStartOfWeek(showingSelectedWeek), getEndOfWeek(showingSelectedWeek));
          setGoogleEvents(response.events);
          fetchTimeEntries();
          notificationActions?.pushSuccessNotification("Entry successfully created.");
        } catch (error) {
          captureException(error);
          notificationActions?.pushErrorNotificationMessage("Error creating time entry. Please try again.");
        }
      } else {
        notificationActions?.pushErrorNotificationMessage("Please select a project before creating a time entry.");
      }
      setIsLoading(false);
    }
  };

    const handleCreateTimeEntryWithoutEvent = async (timeEntry: IEasyClockTimeEntryConfig) => {
            setIsLoading(true);
            if (timeEntry && timeEntry?.clockifyProject?.id && workspaceID) {
                try {
                      await createTimeEntryWithoutEvent(
                          workspaceID,
                          timeEntry.description,
                          timeEntry.clockifyProject.id,
                          timeEntry.billable,
                          timeEntry.clockifyTagIds,
                          formatDate(timeEntry.start_date),
                          formatDate(timeEntry.end_date)
                      );
                    fetchTimeEntries();
                    notificationActions?.pushSuccessNotification(
                        "Entry successfully created."
                    );
                } catch (error) {
                    notificationActions?.pushErrorNotificationMessage(
                        "Error creating time entry. Please try again."
                    );
                }
            } else {
                notificationActions?.pushErrorNotificationMessage(
                    "Please select a project before creating a time entry."
                );
            }
            setIsLoading(false);
    };

    

  const changeEventStatus = (googleId: string, status: string) => {
    if (googleEvents) {
      const ignoredEventIndex = googleEvents.findIndex(event => event.google_id === googleId);

      if (ignoredEventIndex !== -1) {
        const ignoredEvent = googleEvents[ignoredEventIndex];
        ignoredEvent.status = status;

        const updatedEvents = [...googleEvents]
        updatedEvents[ignoredEventIndex] = ignoredEvent;
        setGoogleEvents(updatedEvents);
      }
    }
  };

  const handleChangeEventStatus = async (event: IEasyClockEvent, action: string) => {
    if (googleEvents) {
      setIsLoading(true);
      try {
        if (action === EVENT_ACTION.IGNORE) {
          await ignoreEvent(event.google_id);
          changeEventStatus(event.google_id, EVENT_STATUS.IGNORED);
          notificationActions?.pushSuccessNotification("Event ignored.");
        }else if (action === EVENT_ACTION.RETRIEVE) {
          await resetEventStatus(event.google_id);
          changeEventStatus(event.google_id, EVENT_STATUS.PENDING);
          notificationActions?.pushSuccessNotification("Event status reset.");
        } else if (action === EVENT_ACTION.DELETE) {
          await deleteTimeEntry(event.time_entry_id);
          fetchTimeEntries();
          changeEventStatus(event.google_id, EVENT_STATUS.PENDING);
          notificationActions?.pushSuccessNotification("Event deleted.");
        }
      } catch (error) {
        notificationActions?.pushErrorNotificationMessage("Error changing event status. Please try again.");
      }
      setIsLoading(false);
    }
  }

  const handleDeleteTimeEntry = async (timeEntry: IEasyClockTimeEntry) => {
    setIsLoading(true);
    try {
      await deleteTimeEntry(timeEntry.id);
      notificationActions?.pushSuccessNotification("Time entry deleted.");
      const response = await getEvents(getStartOfWeek(showingSelectedWeek), getEndOfWeek(showingSelectedWeek));
      setGoogleEvents(response.events);
      fetchTimeEntries();
    } catch (error) {
      captureException(error);
      notificationActions?.pushErrorNotificationMessage("Error deleting time entry. Please try again.");
    }
    setIsLoading(false);
  }

  const getWorkingDaysOfOf = (week: number) => {
    const days = [];
    const currentDay = parseDate(getStartOfWeek(week));
    const endDay = parseDate(getEndOfWeek(week));

    while (currentDay.isSameOrBefore(endDay)) {
      days.push(currentDay.clone());
      currentDay.add(1, "day");
    }

    return days.filter((day) => isWorkingDay(day));
  };

  return (
    <>
    <Header />
      <br/>
      <Container className="mt--7" fluid>
        <h3 className="themeFontColor">Events</h3>
        <br/>
          <div className="row">
            <div className="col-mx-12">
              <div className="d-flex justify-content-end">
                <Button
                  className="m-2"
                  color="primary"
                  onClick={handleAddAccount}
                >
                  Add Account
                </Button>
              </div>
            </div>
          </div>
          <br />
            <>
              <EventsTable
                googleEvents = {googleEvents}
                easyClockTimeEntriesConfigs = {easyClockTimeEntriesConfigs}
                clockifyProjects = {clockifyProjects}
                clockifyTags = {clockifyTags}
                showingSelectedWeek = {showingSelectedWeek}
                isLoading = {isLoading}
                defaultClockifyTag = {defaultClockifyTag}
                setSelectedWeek = {setSelectedWeek}
                setEasyClockTimeEntriesConfigs = {setEasyClockTimeEntriesConfigs}
                handleChangeEventStatus = {handleChangeEventStatus}
                handleCreateTimeEntry = {handleCreateTimeEntry}
              />
            </>
            <br />
            <h3 className="themeFontColor">Time Entries</h3>
            <br/>
            <Week
              weekRange = {getWorkingDaysOfOf(showingSelectedWeek)}
              clockifyTags={clockifyTags}
              clockifyProjects={clockifyProjects}
              workspaceId={workspaceID}
              handleCreateTimeEntry = {handleCreateTimeEntryWithoutEvent}     
              isLoading = {isLoading} 
              timeEntries={timeEntries}
              deleteTimeEntry = {handleDeleteTimeEntry}
              weeklyWorkingHours={weeklyWorkingHours}
            />
      </Container>
    </>
  );
}

export default EasyClock;
