import React from "react";
import { Alert, Image } from "react-bootstrap";
import notFoundImg from "../../assets/images/notFoundImg.svg";

function NotFound() {
  return (
    <div className="justify-content-md-center">
      <Image
        src={notFoundImg}
        width="400"
        alt="Imagen de error"
        className="rounded mx-auto d-block"
       />
      <Alert variant="primary" className="text-center m-5">
        <Alert.Heading>Oops! Algo salió mal</Alert.Heading>
      </Alert>
    </div>
  );
}

export default NotFound;
