import { useEffect } from "react";
import Header from "../../components/Headers";
import "./settings.css";
import { useNotificationActionContext } from "../../contexts/notificationContext";
import { MessageListener } from "../../utils/EventHandler";
import { getUserId } from "../../services/auth";
import { easyClockEditUrlFor } from "../../utils/urlUtils";


function ExternalIframe(props: {src: string, messageListener: MessageListener, className?: string}): JSX.Element {
  const messageEventListener = (event: MessageEvent) => {
    if(event.origin !== origin()) return;
    props.messageListener.handle(event);
  };

  const origin = () => {
    const linkElement = document.createElement("a");  
    linkElement.href = props.src;  
    return `${linkElement.protocol}//${linkElement.host}`;  
  }

  useEffect(()=> {
    addEventListener("message", messageEventListener);

    return () => { 
      removeEventListener("message", messageEventListener);
    }
  }, []);

  return (
    <iframe src={props.src} className={`h-100 w-100 ${props.className}`}></iframe>
  )
}

function EasyClockSettings() {
  const notificationActions = useNotificationActionContext();
  const onSuccess = (message: string) => notificationActions?.pushSuccessNotification(message);
  const onError = (message: string) => notificationActions?.pushErrorNotificationMessage(message);
  const messageListener = new MessageListener();
  messageListener.addMessage("error", onError);
  messageListener.addMessage("success", onSuccess);
  
  return (
    <>
      <Header />
      <br />
      <ExternalIframe messageListener={messageListener} src={easyClockEditUrlFor(getUserId())} className="mt--7"/>
    </>
  );
}

export default EasyClockSettings;
