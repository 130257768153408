import { useEffect, useState } from "react";
import {
  Button,
  DropdownButton,
  Dropdown,
  Container,
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { ICalendarSync } from "../../services/calsync/interfaces";
import {
  deleteSync,
  disableSync,
  enableSync,
  getSyncs,
} from "../../services/calsync/calendarSync";
import { createGoogleAccount } from "../../services/common/googleAccount";
import Header from "../../components/Headers";
import { Card } from "reactstrap";
import { paths } from "../../routes";
import { SpinnerInfinity } from "spinners-react";
import { useNotificationActionContext } from "../../contexts/notificationContext";

function CalSync() {
  const navigate = useNavigate();
  const notificationActions = useNotificationActionContext();
  const [syncs, setSyncs] = useState<ICalendarSync[]>([]);
  const [refreshTable, setRefreshTable] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSyncs = async () => {
      setIsLoading(true);
      const calSyncs = await getSyncs();
      setSyncs(calSyncs);
      setIsLoading(false);
    };
    fetchSyncs();
  }, [refreshTable]);

  const AddgoogleAccount = () =>
    window?.google?.accounts.oauth2
      .initCodeClient({
        client_id: process.env.REACT_APP_CALSYNC_CLIENT_ID,
        scope: process.env.REACT_APP_CALSYNC_GOOGLE_SCOPES,
        callback: async (response: { code: string }) => {
          try {
            await createGoogleAccount(response.code, process.env.REACT_APP_CALSYNC_BASE_URL);
            notificationActions?.pushSuccessNotification("Account Added succesfully");
          } catch (error) {
            notificationActions?.pushErrorNotification(error);
          }
        },
        error_callback: (nonOAuthError: { message: string }) => {
          notificationActions?.pushErrorNotificationMessage(nonOAuthError.message);
        },
      })
      .requestCode();

  const handleAddAccount = () => {
    AddgoogleAccount();
  };

  const handleDeleteAccount = async (id: string) => {
    await deleteSync(id);
    setSyncs((sync) => sync.filter((item) => item.id !== id));
  };

  const handleEnableAccount = async (id: string) => {
    await enableSync(id);
    setRefreshTable((oldTable) => oldTable + 1);
  };
  const handleDisableAccount = async (id: string) => {
    await disableSync(id);
    setRefreshTable((oldTable) => oldTable + 1);
  };

  return (
    <>
      <Header />
      <br/>
      <Container className="mt--7" fluid>
        <h3 className="themeFontColor">Calendar Sync</h3>
        <br/>
          <div className="row">
            <div className="col-mx-12">
              <div className="d-flex justify-content-end">
                <Button
                  className="m-2"
                  color="primary"
                  onClick={handleAddAccount}
                >
                  Add Account
                </Button>
                <Button
                  className="m-2"
                  color="primary"
                  onClick={() => navigate(paths.newCalendarSync)}
                >
                  New Sync
                </Button>
              </div>
            </div>
          </div>
        <br/>
        {isLoading ? (
          <div className="text-center">
            <SpinnerInfinity secondaryColor="#4C6FD4" size="80" color="white"/>
          </div>
          ) : (
        <Card className="shadow">
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Source</th>
                <th scope="col">Target</th>
                <th scope="col">State</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {syncs.map((s) => (
                <tr key={s.id}>
                  <td>{s.source}</td>
                  <td>{s.target}</td>
                  {s.status ? <td>Active</td> : <td>Inactive</td>}
                  <td>
                    <div className="d-flex">
                      <DropdownButton
                        id="dropdown-item-button"
                        title=""
                        variant="secondary"
                        size="sm"
                        style={{ position: "static"}}
                      >
                        {s.status && (
                          <Dropdown.Item
                            as="button"
                            onClick={() => handleDisableAccount(s.id)}
                          >
                            Disable
                          </Dropdown.Item>
                        )}
                        {!s.status && (
                          <Dropdown.Item
                            as="button"
                            onClick={() => handleEnableAccount(s.id)}
                          >
                            Enable
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                      <Button
                        variant="light"
                        size="sm"
                        className="ms-2"
                        onClick={() => handleDeleteAccount(s.id)}
                      >
                        <FaTrash />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
        )}
      </Container>
    </>
  );
}

export default CalSync;
