import { useEffect, useState } from "react";
import { Container, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { createSync } from "../../services/calsync/calendarSync";
import { getGoogleAccounts } from "../../services/common/googleAccount";
import { IGoogleAccount } from "../../services/common/interfaces";
import Header from "../../components/Headers";
import React from "react";
import { paths } from "../../routes";
import { useNotificationActionContext } from "../../contexts/notificationContext";

function SyncCalendars() {
  const navigate = useNavigate();
  const notificationActions = useNotificationActionContext();
  const mainContent = React.useRef(null);
  const [source, setSource] = useState("");
  const [target, setTarget] = useState("");
  const [loader, setLoader] = useState(false);
  const [googleAccounts, setGoogleAccounts] = useState<IGoogleAccount[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accounts = await getGoogleAccounts(process.env.REACT_APP_CALSYNC_BASE_URL);
        setGoogleAccounts(accounts);
      } catch (error) {
        notificationActions?.pushErrorNotification(error);
      }
    };
    fetchData();
  }, []);

  const setSourceTitle = (): string => {
    return source === "" ? "Source" : source;
  };

  const setTargetTitle = (): string => {
    return target === "" ? "Target" : target;
  };

  const handleTargetButton = (targetEmail: string) => {
    setTarget(targetEmail);
  };
  const handleSourceButton = (sourceEmail: string) => {
    setSource(sourceEmail);
  };

  const sameAccount = (): boolean => {
    return source === target;
  };

  const isNull = (): boolean => {
    return target === "" || source === "";
  };

  const handleSyncButton = async () => {
    if (sameAccount() || isNull()) {
      notificationActions?.pushErrorNotificationMessage("The source and the target should be different and not empty");
      setLoader(false);
    } else {
      try {
        setLoader(true);
        await createSync(source, target);
        navigate(paths.calendarSync);
      } catch (error) {
        notificationActions?.pushErrorNotification(error);
      }
      setLoader(false);
    }
  };

  return (
    <>
    <Header />
      <br />
      <Container className="mt--7" fluid>
        <h3 className="themeFontColor">Please select a source and a target Account</h3>
        <div className="main-content" ref={mainContent}>
          <br />
          <div className="row">
            <div className="col">
              <DropdownButton
                id="dropdown-item-button"
                title={setSourceTitle()}
                className="d-flex justify-content-around"
                size="lg"
              >
                {googleAccounts.map((a) => (
                  <Dropdown.Item
                    key={a.id}
                    as="button"
                    onClick={() => {
                      handleSourceButton(a.email);
                    }}
                  >
                    {a.email}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div className="col">
              <DropdownButton
                id="dropdown-item-button"
                title={setTargetTitle()}
                className="d-flex justify-content-around"
                size="lg"
              >
                {googleAccounts.map((a) => (
                  <Dropdown.Item
                    key={a.id}
                    as="button"
                    onClick={() => {
                      handleTargetButton(a.email);
                    }}
                  >
                    {a.email}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </div>
          <br></br>
          <div className="d-flex justify-content-center d-flex align-items-center">
            {!loader && (
              <Button color="success" size="lg" onClick={handleSyncButton}>
                Sync
              </Button>
            )}
            {loader && (
              <Button
                variant="outline-success"
                onClick={handleSyncButton}
                disabled={true}
              >
                Loading...
              </Button>
            )}
          </div>
        </div>
      </Container>
    </>
  );
}

export default SyncCalendars;
