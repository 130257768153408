import React from "react";
import { IEasyClockTimeEntryConfig } from "../../services/easyclock/interfaces";
import "./billable.css"

interface BillableProps {
  easyClockTimeEntryConfig: IEasyClockTimeEntryConfig;
  disabled?: boolean;
  setEasyClockTimeEntryConfig: (easyClockTimeEntryConfig: IEasyClockTimeEntryConfig) => void;
}

const BillableInput: React.FC<BillableProps> = (props) => {
  const {
    disabled,
    easyClockTimeEntryConfig,
    setEasyClockTimeEntryConfig
  } = props;

  const handleBillableChange = (value: boolean) => {
    const updatedSettings: IEasyClockTimeEntryConfig = { ...easyClockTimeEntryConfig };
    updatedSettings.billable = value;
    setEasyClockTimeEntryConfig(updatedSettings)
  };

  return (
    <>
      <input
        className="btn easy-clock-billable-checkbox"
        type="checkbox"
        checked={easyClockTimeEntryConfig.billable}
        onChange={(e) => handleBillableChange(e.target.checked)}
        disabled={disabled || false}
      />
    </>
  );
};

export default BillableInput;