import React, { useState } from "react";
import { IEasyClockTimeEntryConfig } from "../../services/easyclock/interfaces";
import { useNotificationActionContext } from "../../contexts/notificationContext";
import { formatDate, parseDate, calculateEndDate, formatDuration,} from "../../utils/timeUtils";
import DurationParser from "../../utils/durationParser";

interface DurationProps {
  easyClockTimeEntryConfig: IEasyClockTimeEntryConfig;
  setEasyClockTimeEntryConfig: (
    easyClockTimeEntryConfig: IEasyClockTimeEntryConfig
  ) => void;
  disabled?: boolean;
}

const Duration: React.FC<DurationProps> = (props) => {
  const { disabled, setEasyClockTimeEntryConfig, easyClockTimeEntryConfig } =
    props;
  const [value, setValue] = useState(easyClockTimeEntryConfig.duration);

  const notificationActions = useNotificationActionContext();

  const handleDurationTimeChange = (duration: string) => {
    const updatedSettings: IEasyClockTimeEntryConfig = {
      ...easyClockTimeEntryConfig,
    };
    duration = formatDuration(new DurationParser().parse(duration));
    setValue(duration);
    const originalEndDate = new Date(updatedSettings.end_date);
    const newEndDate = calculateEndDate(updatedSettings.start_date, duration);
    if (parseDate(newEndDate).isValid()) {
      originalEndDate.setHours(newEndDate.getHours());
      originalEndDate.setMinutes(newEndDate.getMinutes());
      updatedSettings.end_date = formatDate(originalEndDate);
      updatedSettings.duration = duration;
      setEasyClockTimeEntryConfig(updatedSettings);
    } else {
      notificationActions?.pushErrorNotificationMessage(
        "Please enter a valid date and time format (DD-MM-YYYY HH:MM)"
      );
    }
  };

  return (
    <input
      className="btn btn-outline-dark"
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => handleDurationTimeChange(value)}
      disabled={disabled || false}
    />
  );
};

export default Duration;
