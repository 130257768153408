import React, { useState } from "react";
import { Alert } from "reactstrap";
import "./notification.css";
import { NotificationType } from "../../contexts/notificationContext";

interface NotificationProps {
  key: number;
  type: NotificationType;
  message: string;
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ type, message, onClose}) => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  const colorMap = new Map<NotificationType, string>([
    [NotificationType.SUCCESS, "success"],
    [NotificationType.ERROR, "danger"],
  ]);

  const color = colorMap.get(type);

  return visible ? (
    <Alert color={color} className={"notification"} toggle={handleClose}>
      {message}
    </Alert>
  ) : null;
};

export default Notification;
