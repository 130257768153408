import React, { createContext, useContext, useState, useMemo, useCallback, useRef, ReactNode } from "react"
import { ServiceError } from "../services/servicesErrors";

interface NotificationActionContextType {
  pushErrorNotificationMessage: (message: string) => void;
  pushErrorNotification: (error: ServiceError | unknown) => void;
  pushSuccessNotification: (message: string) => void;
  removeNotification: (id: number) => void;
}

interface NotificationContextProviderProps {
  children: ReactNode;
}

export enum NotificationType {
  SUCCESS,
  ERROR,
}

export type Notification = {
  id: number;
  message: string;
  type: NotificationType;
};

const NotificationContext = createContext<Notification[]>([]);
const NotificationActionContext = createContext<NotificationActionContextType | null>(null);

export const useNotificationContext = () => useContext(NotificationContext);
export const useNotificationActionContext = () => useContext(NotificationActionContext);

const NotificationContextProvider = (props: NotificationContextProviderProps) => {

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const identificator = useRef<number>(0);

  const removeNotification = useCallback((id: number) => {
    setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
  }, [setNotifications]);

  const pushNotification = useCallback((message: string, type: NotificationType) => {
    const newNotification = { id: identificator.current, message, type };
    setNotifications(prevNotifications => [...prevNotifications, newNotification]);
    identificator.current += 1;
    setTimeout(() => {
      removeNotification(newNotification.id);
    }, 5000);
  }, [setNotifications, removeNotification]);

  const pushErrorNotificationMessage = useCallback((message: string) => {
    pushNotification(message, NotificationType.ERROR);
  }, [pushNotification]);

  const pushErrorNotification = useCallback((error: ServiceError | unknown) => {
    if (error instanceof ServiceError) {
      pushNotification(error.message, NotificationType.ERROR);
      return;
    }
    pushNotification("Something went wrong. Try Again", NotificationType.ERROR);

  },[pushNotification]);

  const pushSuccessNotification = useCallback((message: string) => {
    pushNotification(message, NotificationType.SUCCESS);
  },[pushNotification]);

  const actionValue = useMemo(() => ({
    pushErrorNotificationMessage,
    pushErrorNotification,
    pushSuccessNotification,
    removeNotification
  }), [pushErrorNotificationMessage, pushErrorNotification, pushSuccessNotification, removeNotification]);

  return (
    <NotificationContext.Provider value={notifications}>
      <NotificationActionContext.Provider value={actionValue}>
        {props.children}
      </NotificationActionContext.Provider>
    </NotificationContext.Provider>
  );
}

export default NotificationContextProvider
