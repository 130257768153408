/**
 * Base class for errors related to services.
 * @class
 */
export class ServiceError extends Error {
  /**
   * Creates an instance of ServiceError.
   * @param {string} message - The error message.
   */
  constructor(message: string) {
    super(message);
  }
}

/**
 * Class to represent unknown errors related to services.
 * @class
 * @extends ServiceError
 */
export class UnknownError extends ServiceError {
  /**
   * Creates an instance of UnknownError.
   * @param {string} message - The error message.
   */
  constructor(message: string) {
    super(message);
  }
}

/**
 * Class to represent network-related errors related to services.
 * @class
 * @extends ServiceError
 */
export class NetworkError extends ServiceError {
  /**
   * Creates an instance of NetworkError.
   * @param {string} message - The error message.
   */
  constructor(message: string) {
    super(message);
  }
}