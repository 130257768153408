import React from "react";
import "./shimmer.css";

interface ShimmerSizeProps {
  width: string;
  height: string;
}

const Shimmer: React.FC<ShimmerSizeProps> = ({width, height}) => {
  return (
    <div className="shimmer" style={{ width, height }}>
      <div className="shimmer-line"></div>
    </div>
  );
};

export default Shimmer;