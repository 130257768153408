import jwtDecode, { JwtPayload } from "jwt-decode";
import { authServerClient } from "./common/http";
import Cookies from "universal-cookie";
import { captureException } from "@sentry/react";

export interface IGoogleJWT {
  email: string;
  name: string;
  picture: string;
  exp: number;
}

const JWT_KEY = process.env.REACT_APP_JWT_KEY;

export const writeUser = (jwt: string): void => {
  localStorage.setItem(JWT_KEY!, jwt);
  const cookies = new Cookies();
  cookies.set(JWT_KEY!, jwt, {
    path: "/",
    domain: process.env.REACT_APP_DOMAIN,
  });
};

export const clearUser = () => {
  localStorage.clear();
};

export const readUser = (): IGoogleJWT | undefined => {
  const jwt = localStorage.getItem(JWT_KEY!);

  if (!jwt) return;

  return jwtDecode<IGoogleJWT>(jwt);
};

export const getUserId = (): string | undefined => {
  try {
    const user = readUser() as JwtPayload;
    return user.sub;
  }
  catch (e) {
    captureException(e)
    return undefined;
  }
};

export const loggedIn = (): boolean => {
  const user = readUser();

  if (!user) return false;

  return user.exp > Math.floor(Date.now() / 1000);
};

export interface IIdTokenResponse {
  idToken: string;
}

export const exchangeCodeForIdToken = async (code: string): Promise<IIdTokenResponse> => {
  const client = authServerClient();
  const idToken = await client.httpPost<IIdTokenResponse>("api/v1/tokens", { authorizationCode: code });
  return idToken;
};
