import axios from "axios";
import { EasyClockEventsResponse } from "../easyclock/easyClock";
import { handleAxiosErrors } from "./handleErorrs";

interface HttpHeaders {
  headers: { Authorization: string };
}

class HttpClient {
  private baseUrl: string;
  private jwt: string;

  constructor(baseUrl: string, jwt: string) {
    this.baseUrl = baseUrl;
    this.jwt = jwt;
  }

  private get headers(): HttpHeaders {
    return {
      headers: { Authorization: `Bearer ${this.jwt}` },
    };
  }

  private getUrl(path: string): string {
    return `${this.baseUrl}/${path}`;
  }

  public async httpGet<T>(path: string): Promise<T> {
    try {
      const url = this.getUrl(path);
      return axios.get<T>(url, this.headers).then((response) => response.data);
    } catch (error) {
      handleAxiosErrors(error);
      return {} as T;
    }
  }

  public async httpPost<T>(path: string, body = {}): Promise<T> {
    try {
      const url = this.getUrl(path);
      const response = await axios.post<T>(url, body, this.headers);
      return response.data;
    } catch (error) {
      handleAxiosErrors(error);
      return {} as T;
    }
  }

  public async httpUpdate<T>(path: string, body: EasyClockEventsResponse): Promise<T> {
    try {
      const url = this.getUrl(path);
      return axios.put<T>(url, body, this.headers).then((response) => response.data);
    } catch (error) {
      handleAxiosErrors(error);
      return {} as T;
    }
  }

  public async httpDelete(path: string): Promise<void> {
    try {
      const url = this.getUrl(path);
      return axios.delete(url, this.headers);
    } catch (error) {
      handleAxiosErrors(error);
    }
  }
}

function newClient(url: string | undefined): HttpClient {
  if (!url) {
    throw new Error("url not defined");
  }
  const jwt = localStorage.getItem("jwt") ?? "";
  return new HttpClient(url, jwt);
}

function calSyncClient(): HttpClient {
  const url = process.env.REACT_APP_CALSYNC_BASE_URL;
  return newClient(url);
}

function easyClockClient(): HttpClient {
  const url = process.env.REACT_APP_EASYCLOCK_BASE_URL;
  return newClient(url);
}

function authServerClient(): HttpClient {
  const url = process.env.REACT_APP_AUTHSERVER_BASE_URL;
  return newClient(url);
}

export { calSyncClient, easyClockClient, authServerClient }

export default newClient;

