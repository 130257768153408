import { Moment } from "moment";
import { FC, useState } from "react";
import { Card } from "react-bootstrap";
import {
  AiFillDollarCircle,
  AiOutlineDelete,
  AiOutlineDollar,
} from "react-icons/ai";
import { Button } from "reactstrap";
import AddHourModal from "../AddHourModal";
import {
  IClockifyTag,
  IEasyClockProject,
  IEasyClockTimeEntry,
  IEasyClockTimeEntryConfig,
} from "../../services/easyclock/interfaces";
import { Table } from "reactstrap";
import {
  parseDate,
  getDateNow,
  updateTime,
  formatDuration,
  getDuration,
  formatDate,
} from "../../utils/timeUtils";
import { DEFAULT_START_HOUR } from "../../constants/constants";
import { isSameDay } from "../../utils/timeUtils";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  ChakraProvider,
} from "@chakra-ui/react";
import FakeRows from "../FakeRows";
import "./week.css";

interface DayProps {
  day: Moment;
  clockifyTags: IClockifyTag[] | null;
  clockifyProjects: IEasyClockProject[] | null;
  workspaceId: string | null;
  handleCreateTimeEntry: (timeEntry: IEasyClockTimeEntryConfig) => void;
  timeEntries: IEasyClockTimeEntry[];
  isLoading: boolean;
  deleteTimeEntry: (timeEntry: IEasyClockTimeEntry) => void;
  dailyWorkingHours: number;
}

interface WeekProps {
  weekRange: Moment[];
  clockifyTags: IClockifyTag[] | null;
  clockifyProjects: IEasyClockProject[] | null;
  workspaceId: string | null;
  handleCreateTimeEntry: (timeEntry: IEasyClockTimeEntryConfig) => void;
  isLoading: boolean;
  timeEntries: IEasyClockTimeEntry[];
  deleteTimeEntry: (timeEntry: IEasyClockTimeEntry) => void;
  weeklyWorkingHours: number;
}

const Day: FC<DayProps> = (props) => {
  const {
    day,
    clockifyTags,
    clockifyProjects,
    workspaceId,
    handleCreateTimeEntry,
    isLoading,
    timeEntries,
    deleteTimeEntry,
    dailyWorkingHours,
  } = props;
  const [showModal, setShowModal] = useState(false);

  const getHoursLeft = () => {
    const startDate = parseDate(
      updateTime(formatDate(getDateNow()), DEFAULT_START_HOUR)
    );
    const endDate = startDate.clone().add(dailyWorkingHours, "hour");

    const workedHours = timeEntries.reduce(
      (partialSum, timeEntry) =>
        partialSum.add(
          getDuration(timeEntry.start_at, timeEntry.end_at),
          "milliseconds"
        ),
      startDate.clone()
    );

    return formatDuration(endDate.diff(workedHours, "millisecond"));
  };

  const hoursLeft: string = getHoursLeft();

  function selectedTagNames(timeEntry: IEasyClockTimeEntry): string[] {
    return timeEntry.tag_ids
      .map((tagId) => clockifyTags?.find((tag) => tag.id === tagId))
      .filter((tag): tag is IClockifyTag => !!tag)
      .map((tag) => tag.name);
  }

  return (
    <>
      <TabPanel>
        <Card className="shadow">
          <div className="custom-control custom-switch d-flex justify-content-between align-items-center pt-1 pb-1 pr-4 pl-4">
            <div>
              <span>{hoursLeft}hs Left</span>
            </div>
            <div>
              <Button
                className="btn p-1 m-2"
                color="primary"
                onClick={() => setShowModal(!showModal)}
              >
                Add Hour
              </Button>
            </div>
          </div>
          <Table className="align-items-center easy-clock-table" responsive>
            <thead className="thead-light">
              <tr>
                <th className="week-event-col" scope="col">
                  Description
                </th>
                <th className="week-tags-col" scope="col">
                  Tags
                </th>
                <th className="week-duration-col" scope="col">
                  Duration
                </th>
                <th className="week-project-col" scope="col">
                  Project
                </th>
                <th className="week-billable-head" scope="col">
                  $
                </th>
                <th className="week-action-col" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <FakeRows columns={6} rows={3} />
            ) : (
              <tbody>
                {timeEntries &&
                  timeEntries.map((timeEntry) => {
                    return (
                      <tr key={timeEntry.id}>
                        <td className="week-event-col easy-clock-td-event">
                          <div className="d-flex flex-column">
                            <span>{timeEntry.description}</span>
                          </div>
                        </td>
                        <td className="week-tags-col">
                          {selectedTagNames(timeEntry).join(", ")}
                        </td>
                        <td className="week-duration-col">
                          {formatDuration(
                            getDuration(timeEntry.start_at, timeEntry.end_at)
                          )}
                        </td>
                        <td className="week-project-col">
                          {
                            clockifyProjects?.find((project) => {
                              return timeEntry.project_id == project.id;
                            })?.name
                          }
                        </td>
                        <td className="week-billable-col">
                          {timeEntry.billable ? (
                            <AiFillDollarCircle size={24} />
                          ) : (
                            <AiOutlineDollar size={24} />
                          )}
                        </td>
                        <td className="week-action-col">
                          <Button
                            color="danger"
                            onClick={() => deleteTimeEntry(timeEntry)}
                          >
                            <AiOutlineDelete />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </Table>
        </Card>
      </TabPanel>

      <AddHourModal
        clockifyTags={clockifyTags}
        clockifyProjects={clockifyProjects}
        workspaceId={workspaceId}
        dateToBeUploaded={formatDate(day)}
        show={showModal}
        setShow={setShowModal}
        startingDuration={hoursLeft}
        handleCreateTimeEntry={handleCreateTimeEntry}
        isLoading={isLoading}
      />
    </>
  );
};

const Week: FC<WeekProps> = (props) => {
  const {
    weekRange,
    clockifyTags,
    clockifyProjects,
    workspaceId,
    handleCreateTimeEntry,
    isLoading,
    timeEntries,
    weeklyWorkingHours,
    deleteTimeEntry,
  } = props;

  const timeEntriesFor = (day: Moment) => {
    return timeEntries?.filter((timeEntry) =>
      isSameDay(timeEntry.start_at, formatDate(day))
    );
  };

  const dailyWorkingHours = weeklyWorkingHours / weekRange.length;

  return (
    <ChakraProvider>
      <Tabs>
        <TabList>
          {weekRange.map((day) => (
            <Tab key={formatDate(day)}>{`${day.format("dddd")} - ${day.format(
              "DD/MM"
            )}`}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {weekRange.map((day) => (
            <Day
              key={formatDate(day)}
              day={day}
              clockifyTags={clockifyTags}
              clockifyProjects={clockifyProjects}
              workspaceId={workspaceId}
              handleCreateTimeEntry={handleCreateTimeEntry}
              isLoading={isLoading}
              timeEntries={timeEntriesFor(day)}
              deleteTimeEntry={deleteTimeEntry}
              dailyWorkingHours={dailyWorkingHours}
            />
          ))}
        </TabPanels>
      </Tabs>
    </ChakraProvider>
  );
};

export default Week;
