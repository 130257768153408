const MILLISECONDS_IN_HOUR = 3600000;
const MILLISECONDS_IN_MINUTE = 60000;
/**
 * Utility class for parsing durations in the format of "XhYm", where X represents hours and Y represents minutes.
 */
class DurationParser {
  /**
   * Parses the given duration string and returns the total duration in milliseconds.
   * @param duration - The duration string to parse.
   * @returns The total duration in milliseconds.
   */
  parse(duration: string): number {
    const hours = this.parseHours(duration);
    const minutes = this.parseMinutes(duration);
    return hours * MILLISECONDS_IN_HOUR + minutes * MILLISECONDS_IN_MINUTE;
  }

  /**
   * Parses the hours portion of the duration string.
   * @param duration - The duration string to parse.
   * @returns The number of hours parsed from the duration string.
   */
  private parseHours(duration: string) {
    return this.getNumberThatMatches(duration, /(\d+)h|(\d+)$/);
  }

  /**
   * Parses the minutes portion of the duration string.
   * @param duration - The duration string to parse.
   * @returns The number of minutes parsed from the duration string.
   */
  private parseMinutes(duration: string) {
    return this.getNumberThatMatches(duration, /(\d+)m/);
  }

  /**
   * Extracts the number that matches the given regular expression from the duration string.
   * @param duration - The duration string to extract the number from.
   * @param regex - The regular expression to match against the duration string.
   * @returns The number extracted from the duration string, or 0 if no match is found.
   */
  private getNumberThatMatches(duration: string, regex: RegExp) {
    const match = duration.match(regex);

    if (match === null) {
      return 0;
    }

    return parseInt(match[0]);
  }
}

export default DurationParser;
