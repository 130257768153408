import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { initialize } from "./sentry";

function App() {
  initialize();
  return useRoutes(routes);
}

export default App;
