import { Alert, Image } from "react-bootstrap";
import serverErrorImg from "../../assets/images/serverErrorImg.svg";

function ServerError() {
  return (
    <div className="justify-content-md-center">
      <Image
        src={serverErrorImg}
        width="600"
        className="rounded mx-auto d-block"
      ></Image>
      <Alert variant="primary" className="text-center m-5">
        <Alert.Heading>Oops! Algo salio mal</Alert.Heading>
        <p>Lo sentimos, algo salio mal. Intentalo de nuevo mas tarde.</p>
      </Alert>
    </div>
  );
}
export default ServerError;
