import axios, { AxiosError } from "axios";
import { UnknownError, NetworkError } from "../servicesErrors";
import { captureException } from "../../sentry";

/**
 * Handles Axios errors and throws specific service-related errors.
 * @param {unknown} error - The error to handle.
 * @throws {NetworkError} Thrown if a network error occurs.
 * @throws {UnknownError} Thrown if an unknown error occurs.
 */
export function handleAxiosErrors(error: unknown): void {
  captureException(error);
  if (axios.isAxiosError(error)) {
    const axiosError: AxiosError = error;
    if (axiosError.code === "ERR_NETWORK") {
      throw new NetworkError("A Network Error occurred");
    }
  }
  throw new UnknownError("An error occurred. Try Again");
}
