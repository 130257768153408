import newClient from "../common/http";
import { IGoogleAccount } from "../common/interfaces";

export async function createGoogleAccount(code: string, url: string | undefined) {
  const body = { code: code };
  return await newClient(url).httpPost<IGoogleAccount>("api/v1/google_accounts", body);
}

export async function getGoogleAccounts(url: string | undefined) {
  return await newClient(url).httpGet<IGoogleAccount[]>("api/v1/google_accounts");
}

