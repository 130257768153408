import { FC } from "react";
import { IEasyClockTimeEntryConfig } from "../../services/easyclock/interfaces";

interface DescriptionProps {
    easyClockTimeEntryConfig: IEasyClockTimeEntryConfig;
    setEasyClockTimeEntryConfig: (
        easyClockTimeEntryConfig: IEasyClockTimeEntryConfig
    ) => void;
    disabled?: boolean;
}

export const Description: FC<DescriptionProps> = (props) => {
    const { disabled, setEasyClockTimeEntryConfig, easyClockTimeEntryConfig } =
        props;

    const handleDescriptionChange = (new_description: string) => {
        const updatedSettings: IEasyClockTimeEntryConfig = {
            ...easyClockTimeEntryConfig,
        };
        updatedSettings.description = new_description;
        setEasyClockTimeEntryConfig(updatedSettings);
    };

    return (
        <input
            className="form-control"
            type="text"
            value={easyClockTimeEntryConfig.description}
            onChange={(e) => handleDescriptionChange(e.target.value)}
            disabled={disabled || false}
        />
    );
};
