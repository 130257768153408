import "./login.scss";
import CrunchLoopLogo from "../../assets/images/crunchlogo.png";
import GoogleIcon from "../../assets/images/google.png";
import { useGoogleLogin, CodeResponse } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { writeUser, exchangeCodeForIdToken } from "../../services/auth";
import { Image } from "react-bootstrap";
import { paths } from "../../routes";
import { useNotificationActionContext } from "../../contexts/notificationContext";

function Login() {
  const notificationActions = useNotificationActionContext();
  const navigate = useNavigate();
  const onCodeRetrivalSuccess = async (codeResponse: CodeResponse) => {
    if (!codeResponse) {
      notificationActions?.pushErrorNotificationMessage("Authorization code could not be retrieved");
      return;
    }
    const id_token = (await exchangeCodeForIdToken(codeResponse.code)).idToken;
    writeUser(id_token);
    navigate(paths.root);
  };

  const onLoginFailure = () => {
    notificationActions?.pushErrorNotification("Sorry, we had a problem while performing authentication");
  };

  const login = useGoogleLogin({ flow: "auth-code", hosted_domain: "crunchloop.io", onSuccess: onCodeRetrivalSuccess, onError: onLoginFailure });
  return (
    <>
      <div className="login">
        <Image src={CrunchLoopLogo} className="app-logo" alt="logo" />
        <p>Please Log in with your Crunchloop&apos;s Google Workspace.</p>
        <div className="google-btn" onClick={login}>
          <div className="google-icon-wrapper">
            <img className="google-icon" src={GoogleIcon}/>
          </div>
          <p className="btn-text"><b>Sign in with Google</b></p>
        </div>
      </div>
    </>
  );
}
export default Login;