export {};

export const EVENT_STATUS = {
  IGNORED: "ignored",
  PENDING: "pending",
  ADDED: "added",
};

export const EVENT_ACTION = {
  IGNORE: "ignore",
  RETRIEVE: "retrieve",
  DELETE: "delete",
};

export const DEFAULT_START_HOUR = "09:00";
export const DEFAULT_START_DURATION = "02:00";
export const STANDARD_WEEKLY_WORKING_HOURS = 40;
