import { Navbar, Container, Image } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { IGoogleJWT, clearUser } from "../../services/auth";
import "./navbar.css";
import { DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, UncontrolledDropdown } from "reactstrap";
import { paths }  from "../../routes";

function Header({ email, picture }: IGoogleJWT) {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    clearUser();
    navigate(paths.login);
  };

  return (
    <header>
      <Navbar className="navbar-top" expand="md" id="navbar-main" bg="light">
        <Container fluid>
          <Link
            className="h4 mb-0 text-blue d-none d-lg-inline-block"
            to={paths.events}
          >
            Crunchloop Hub
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center header-content">
                  <Media className="ml-2 d-none d-lg-block">
                    <p className="mb-0 text-sm font-weight-bold">{email} </p>
                  </Media>
                  <Image
                    className="profile-picture"
                    src={picture}
                    roundedCircle
                    thumbnail
                  />
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem to={paths.settings} tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={handleLogoutClick}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
