import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/template/icons/css/icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/template/scss/argon-dashboard-react.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter } from "react-router-dom";
import NotificationContextProvider from "./contexts/notificationContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID_LOGIN!}>
        <NotificationContextProvider>
          <App />
        </NotificationContextProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
