import moment, { Moment } from "moment";
import { IEasyClockEvent } from "../services/easyclock/interfaces";

/**
 * Formats a date as a string in UTC.
 *
 * @param {Date|Moment|string} date - The date to format as a string.
 * @returns {string} The formatted date string in UTC.
 */
function formatDate(date: Moment | Date): string;
function formatDate(date: string): string;
function formatDate(date: unknown): unknown {
  if (typeof date === "string") {
    return moment(date).utc().toISOString();
  } else if (moment.isMoment(date) || date instanceof Date) {
    return date.toISOString();
  }
  throw new Error("Date should be Moment or String type.");
}
/**
 * Formats a duration in miliseconds as "HH:mm" (hours and minutes) format.
 *
 * @param {number} duration - The duration in miliseconds to format.
 * @returns {string} The formatted duration string in "HH:mm" format.
 */

function formatDuration(duration: number): string {
  return moment(new Date(duration)).utc().format("HH:mm");
}

/**
 * Formats a date as "YYYY-MM-DDTHH:mm" format.
 *
 * @param {string} date - The date to format as a string.
 * @returns {string} The formatted date string in "YYYY-MM-DDTHH:mm" format.
 */
function formatISO8601(date: string): string {
  return moment(date).format("YYYY-MM-DDTHH:mm");
}

/**
 * Parses a time value from a string in "HH:mm" format.
 *
 * @param {string} value - The time value to parse as a string.
 * @returns {Object} A moment.js object representing the parsed time value.
 */
function formatTime(value: string): moment.Moment {
  return moment(value, "HH:mm");
}

/**
 * Gets the current week number.
 *
 * @returns {number} The current week number.
 */
function getCurrentWeek(): number {
  return moment().week();
}

/**
 * Gets the current Date using Moment.js.
 *
 * @returns {moment.Moment} Object Moment representing the current Date.
 */
function getDateNow(): moment.Moment {
  return moment();
}

/**
 * Gets the end date of a specific week as "YYYY-MM-DD".
 *
 * @param {number} week - The week number.
 * @returns {string} The end date of the week in "YYYY-MM-DD" format.
 */
function getEndOfWeek(week: number): string {
  return moment().week(week).endOf("week").format("YYYY-MM-DD");
}

/**
 * Gets the start date of a specific week as "YYYY-MM-DD".
 *
 * @param {number} week - The week number.
 * @returns {string} The start date of the week in "YYYY-MM-DD" format.
 */
function getStartOfWeek(week: number): string {
  return moment().week(week).startOf("week").format("YYYY-MM-DD");
}

/**
 * Parses a date or string and returns a moment.js object.
 *
 * @param {Date|string} date - The date or string to parse.
 * @returns {Object} A moment.js object representing the parsed date or string.
 */
function parseDate(date: Date | string): moment.Moment {
  return moment(date);
}

/**
 * Checks if the given event is in the future.
 *
 * @param {IEasyClockEvent} event - The event to be checked.
 * @returns {boolean} Returns true if the event is in the future, false otherwise.
 */
function isFutureEvent(event: IEasyClockEvent) {
  return parseDate(event.start_date) > getDateNow();
}

/**
 * Updates the time of a date.
 *
 * @param {string} date - The date to update.
 * @param {string} new_time - The new time to set.
 * @returns {string} The updated date with the new time.
 */
function updateTime(date: string, new_time: string): string {
  const new_date_time = moment.utc(new_time, "HH:mm");
  const new_date = formatDate(
    parseDate(date).set({
      hours: new_date_time.hours(),
      minutes: new_date_time.minutes(),
    })
  );
  return new_date;
}

/**
 * Calculates the end date based on the updated settings and duration.
 *
 * @param {string} updatedSettings - The updated settings.
 * @param {string} duration - The duration.
 * @returns {Date} The calculated end date.
 */
function calculateEndDate(updatedSettings: string, duration: string) {
  const originalDate = parseDate(updatedSettings);
  const formattedTime = formatTime(duration);
  const endDate = originalDate
    .add(formattedTime.hours(), "hours")
    .add(formattedTime.minutes(), "minutes");
  return new Date(endDate.utc().format());
}

/**
 * Checks if a day is a working day.
 *
 * @param {moment.Moment} day - The day to check.
 * @returns {boolean} Returns true if the day is a working day, false otherwise.
 */
function isWorkingDay(day: Moment) {
  return day.isoWeekday() < 6;
}

/**
 * Checks if two dates are the same day.
 *
 * @param {string} date1 - The first date.
 * @param {string} date2 - The second date.
 * @returns {boolean} Returns true if the dates are the same day, false otherwise.
 */
function isSameDay(date1: string, date2: string): boolean {
  return parseDate(date1).isSame(parseDate(date2), "day");
}

/**
 * Gets the duration between two dates in milliseconds.
 *
 * @param {string} startDate - The start date.
 * @param {string} endDate - The end date.
 * @returns {number} The duration between the two dates.
 */
function getDuration(startDate: string, endDate: string): number {
  const startMoment = parseDate(startDate);
  const endMoment = parseDate(endDate);
  const duration = endMoment.diff(startMoment, "millisecond");
  return duration;
}

export {
  formatDate,
  formatDuration,
  formatISO8601,
  formatTime,
  getCurrentWeek,
  getDateNow,
  getEndOfWeek,
  getStartOfWeek,
  parseDate,
  isFutureEvent,
  updateTime,
  calculateEndDate,
  isWorkingDay,
  isSameDay,
  getDuration,
};
