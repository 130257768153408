import {easyClockClient} from "../common/http";
import { IEasyClock, IEasyClockEvent, IEasyClockUser, IClockifyTags, IClockifyProjects, IEasyClockTimeEntry } from "./interfaces";

export type EasyClockEventsResponse = {
  google_id?: string;
  workspace_id?: string;
  description?: string;
  project_id?: string;
  billable?: boolean;
  tag_ids?: string[];
  start?: string;
  end?: string;
  clockify_api_key?: string;
  weekly_working_hours?: string;
}

export async function getEvents(startDate: string, endDate: string) {
  const url = `api/v1/events?start_date=${startDate}&end_date=${endDate}`;
  return easyClockClient().httpGet<IEasyClock>(url);
}

export async function resetEventStatus(google_id: string) {
  return easyClockClient().httpPost<IEasyClockEvent>(`api/v1/events/reset_event_status/${google_id}`);
}

export async function getTags() {
  return easyClockClient().httpGet<IClockifyTags>("api/v1/tags");
}

export async function getProjects() {
  return easyClockClient().httpGet<IClockifyProjects>("api/v1/projects");
}

export async function getTimeEntries() {
  return easyClockClient().httpGet<IEasyClockTimeEntry[]>(
    "api/v1/time_entries"
  );
}


export async function ignoreEvent(google_id: string) {
  const body = { google_id };

  return easyClockClient().httpPost<IEasyClockEvent>("api/v1/events", body);
}

export async function createTimeEntry(google_id: string, workspace_id: string, description: string, project_id: string, billable: boolean, tag_ids: string[], start: string, end: string) {
  const body = { google_id: google_id, workspace_id: workspace_id, description: description, project_id: project_id, billable: billable, tag_ids: tag_ids, start: start, end: end };

  return easyClockClient().httpPost<IEasyClockEvent>("api/v1/clockify/time_entries", body);
}

export async function createTimeEntryWithoutEvent(workspace_id: string, description: string, project_id: string, billable: boolean, tag_ids: string[], start: string, end: string) {
  const body = { workspace_id: workspace_id, description: description, project_id: project_id, billable: billable, tag_ids: tag_ids, start: start, end: end };

  return easyClockClient().httpPost<IEasyClockEvent>("api/v1/clockify/time_entries", body);
}

export async function deleteTimeEntry(time_entry_id: string) {
  return easyClockClient().httpDelete(`api/v1/clockify/time_entries/${time_entry_id}`);
}

export async function getUserSettings(user_id: string) {
  return easyClockClient().httpGet<IEasyClockUser>(`api/v1/users/${user_id}`);
}

export async function setClockifyApiKey(user_id: string, clockify_api_key: string) {
  const body = { clockify_api_key }

  return easyClockClient().httpUpdate<IEasyClock>(`api/v1/users/${user_id}`, body);
}

export async function setUserWorkingHours(user_id: string, weekly_working_hours: string) {
  const body = { weekly_working_hours }

  return easyClockClient().httpUpdate<IEasyClock>(`api/v1/users/${user_id}`, body);
}
